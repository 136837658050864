import { useApolloClient, useQuery } from '@apollo/client';
import { parseISO } from 'date-fns';
import { get, orderBy, uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  type AttachmentType,
  AttachmentsDocument,
  type ButtonClickType,
  type ButtonType,
  ChatMessageDocument,
  type ChatMessageType,
  ChatMessagesDocument,
  type ChatType,
  type WebhookRequestType,
} from 'frontend/api/generated';
import { mapToObject } from 'frontend/utils';
import randomUUID from 'frontend/utils/randomUUID';

import useWebhookRequests from './useWebhookRequests';
import { SYSTEM_MESSAGE_TYPE } from '../utils/constants';
import type { CompleteChatMessageType } from '../utils/types';

const CHAT_MESSAGES_LIMIT = Number(localStorage.getItem('CHAT_MESSAGES_LIMIT') || 100);

function getCreatedTimestamp({ created, createdAt }): number {
  return parseISO(created || createdAt).getTime();
}

type PartialChatMessage = Omit<
  ChatMessageType,
  'buttons' | 'attachments' | 'imageCarousel' | 'replyCandidates' | 'suggestions'
>;

function mapButton(
  chatMessage: PartialChatMessage,
  type: string,
  extra: Record<string, unknown>,
): (button: ButtonType) => {
  [buttonId: string]: {
    button: ButtonType;
    chatMessage: PartialChatMessage;
  } & {
    [key: string]: unknown;
  };
} {
  return (button) => ({
    [button.id]: { button: { ...button, type }, chatMessage, ...extra },
  });
}

function getUrl(message: PartialChatMessage, chat: ChatType): string | null {
  if (message.webHost && message.webPath) {
    return `https://${message.webHost}${message.webPath}`;
  }

  if (chat.webHost && chat.webPath) {
    return `https://${chat.webHost}${chat.webPath}`;
  }

  return null;
}

// TODO enable for future when we have reviews
export const IS_REVIEW = false;

export const BUTTON_CLICK_CONSTANT_FIELDS = {
  fromBot: false,
  exchangeType: null,
  fromWebhook: false,
  name: 'You',
  sender: 'USER',
};

// Find buttons in chat messages (including suggestions) and bundle them together with button clicks
export const handleButtonClicks = (buttonClicks, chatMessages, extraMessages) => {
  const buttonMapping = [...chatMessages, ...extraMessages].reduce(
    (mapping, { buttons, suggestions, imageCarousel, imageCarouselSize, ...rest }) => {
      const messageButtonsMap = mapToObject(mapButton(rest, 'buttons', { buttons }), buttons || []);
      const suggestionButtonsMap = mapToObject(
        ({ buttons: suggestionButtons }) =>
          mapToObject(mapButton(rest, 'suggestions', { suggestions }), suggestionButtons || []),
        suggestions || [],
      );

      const imageCarouselButtonsMap = mapToObject(
        ({ buttons: imageCarouselButtons }) =>
          mapToObject(
            mapButton(rest, 'imageCarousel', { imageCarousel, imageCarouselSize }),
            imageCarouselButtons || [],
          ),
        imageCarousel || [],
      );

      return {
        ...mapping,
        ...messageButtonsMap,
        ...suggestionButtonsMap,
        ...imageCarouselButtonsMap,
      };
    },
    {},
  );

  return (buttonClicks || []).reduce((clicks, { id, buttonId, time, __typename }) => {
    const { button, chatMessage, ...rest } = buttonMapping[buttonId] || {};
    if (!button || chatMessages[chatMessages.length - 1].created > time) {
      return clicks;
    }

    const buttonClick = {
      ...chatMessage,
      id,
      button: {
        ...button,
        ...rest,
      },
      created: time,
      __typename,
      ...BUTTON_CLICK_CONSTANT_FIELDS,
    };

    return [...clicks, buttonClick];
  }, []);
};

const buttonClicksWithoutChatMessage = (buttonClicks, chatMessages) => {
  const uniqueButtonClicks: ButtonClickType[] = uniqBy(buttonClicks, ({ chatMessageId }) => !chatMessageId);
  return uniqueButtonClicks
    .filter((uniqueButton) => chatMessages.some(({ id }) => id !== uniqueButton.chatMessageId))
    .map(({ chatMessageId }) => chatMessageId);
};

const getGroupedData = (
  chat: ChatType,
  chatMessages: ChatMessageType[],
  webhooks?: Partial<WebhookRequestType>[],
  attachments?: AttachmentType[],
  extraChatMessages?: ChatMessageType[],
) => {
  let previousUrl: string | null = null;
  const allData = [
    ...(chatMessages ?? []),
    ...handleButtonClicks(chat.buttonClicks, chatMessages, extraChatMessages),
    ...(webhooks ?? []),
  ];

  if (chat.feedbacks?.length) {
    const [feedback] = chat.feedbacks;
    allData.push(
      {
        feedback,
        __typename: SYSTEM_MESSAGE_TYPE.FEEDBACK,
        created: feedback?.createdAt,
        id: 'feedback-log-item',
      },
      feedback,
    );
  }

  if (chat?.userLeft) {
    allData.push({
      __typename: SYSTEM_MESSAGE_TYPE.USER_LEFT,
      created: chat?.userLeft,
      id: 'user-left-log-item',
    });
  }

  let dataOrdered = orderBy(allData, getCreatedTimestamp, 'asc');

  // So we keep the same structure
  // [[chatMessage], [chatMessage]]
  dataOrdered = dataOrdered.reduce((acc, currValue) => {
    const currValueCopy = { ...currValue, ...(currValue.attachments && { attachments: currValue.attachments }) };

    // Handle legacy + new attachments
    if (currValueCopy.attachmentIds?.length) {
      const foundAttachments = attachments?.filter(({ id }) => currValueCopy.attachmentIds.includes(id));
      currValueCopy.attachments = [...currValueCopy.attachments, ...(foundAttachments || [])];
    }

    const url = getUrl(currValueCopy, chat);

    if (IS_REVIEW) {
      const foundIndex = acc.findIndex(([{ id }]) => id === currValueCopy.replyToId);

      if (foundIndex > -1) {
        acc[foundIndex].push(currValueCopy);
        return acc;
      }
    }

    const data: CompleteChatMessageType[][] = [
      ...acc,
      ...(url && previousUrl !== url
        ? [
            [
              {
                message: url,
                created: currValueCopy.created,
                __typename: SYSTEM_MESSAGE_TYPE.URL,
                id: randomUUID(),
              },
            ],
          ]
        : []),
      [currValueCopy],
    ];

    if (url) {
      previousUrl = url;
    }

    return data;
  }, [] as CompleteChatMessageType[][]);

  return dataOrdered;
};

const useChatMessages = (chat?: ChatType) => {
  const { botId, chatId } = useParams();

  const [loading, setLoading] = useState(true);
  const [groupedData, setGroupedData] = useState<CompleteChatMessageType[][]>([]);

  const client = useApolloClient();

  const {
    data: chatMessages,
    fetchMore,
    loading: chatMessagesLoading,
  } = useQuery(ChatMessagesDocument, {
    variables: { botId: botId!, chatId: chatId!, limit: CHAT_MESSAGES_LIMIT },
    skip: !botId || !chatId,
    pollInterval: 20000, // refetch every 20 seconds
  });

  const { data: attachmentsData, loading: attachmentsLoading } = useQuery(AttachmentsDocument, {
    variables: { botId: botId as string, chatId: chatId as string },
    skip: !botId || !chatId,
  });

  const { webhooks, loading: webhookDataLoading } = useWebhookRequests(
    botId!,
    chatMessages?.chatMessages?.messages ?? [],
  );

  useEffect(() => {
    const handleGroupedData = async () => {
      // It is possible to get button clicks in the chat that are not related to any paginated chat message
      // In that case we need to fetch the chat message for that button click
      // We use those extra chat messages to get the desired button clicks data and have it in the log, since we grab the button clicks data from the chat messages
      const getChatMessagesWithoutButtonClicks = async (chatData: ChatType) => {
        const messages = chatMessages?.chatMessages?.messages;
        const buttonClicksWithoutChatMessages = buttonClicksWithoutChatMessage(chatData.buttonClicks, messages);

        if (buttonClicksWithoutChatMessages.length) {
          const buttonClicksMissingChatMessages = await Promise.all(
            buttonClicksWithoutChatMessages.map((id) =>
              client.query({ query: ChatMessageDocument, variables: { id: id! } }),
            ),
          );

          return buttonClicksMissingChatMessages;
        }
        return [];
      };

      if (chat && chatMessages?.chatMessages?.messages) {
        const chatMessagesWithoutButtonClicks = await getChatMessagesWithoutButtonClicks(chat);

        const extraChatMessages: ChatMessageType[] | undefined = chatMessagesWithoutButtonClicks
          ?.map(({ data }) => data.chatMessage)
          .filter((msg): msg is ChatMessageType => !!msg);

        return getGroupedData(
          chat,
          get(chatMessages, 'chatMessages.messages', []),
          webhooks,
          get(attachmentsData, 'attachments', []) as AttachmentType[],
          extraChatMessages,
        );
      }
      return [];
    };

    if (!chat) {
      setLoading(false);
    }

    if (chat && (!webhookDataLoading || !chatMessagesLoading || !attachmentsLoading)) {
      handleGroupedData().then((data) => {
        setGroupedData(data);
        setLoading(false);
      });
    }
  }, [
    chat,
    chatMessages,
    client,
    webhooks,
    attachmentsData,
    webhookDataLoading,
    chatMessagesLoading,
    attachmentsLoading,
  ]);

  const loadMore = useCallback(() => {
    const messages = orderBy(get(chatMessages, 'chatMessages.messages', []), ['created'], ['asc']);
    const before = get(messages, '[0].created', null);

    fetchMore({
      variables: { before },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        return {
          chatMessages: {
            ...prev.chatMessages,
            messages: [...(prev.chatMessages?.messages || []), ...(fetchMoreResult.chatMessages?.messages || [])],
          },
        };
      },
    });
  }, [chatMessages, fetchMore]);

  return {
    loading,
    data: groupedData || ([] as CompleteChatMessageType[][]),
    hasMore: (chatMessages?.chatMessages?.remaining || 0) > 0,
    loadMore,
  };
};

export default useChatMessages;
